import { ShoppingListSummary } from './ShoppingListSummary';
import { ShoppingListState, useShoppingLists } from './hooks/useShoppingLists';
import { CreateShoppingList } from './CreateShoppingList';
import { Spinner } from 'src/components/Spinner';
import { ErrorText, HeadingText, Spacer } from 'src/components/common';
import styled from 'styled-components';
import { Link, useNavigate } from 'src/components/Router';
import { PageHeading } from 'src/components/PageHeading';
import { useListDragHandlers } from './hooks/useListDragHandlers';
import { useRef } from 'react';
import { CursorContextProvider } from 'src/hooks/useCursorClientPosition';
import { DragContainer } from '../ShoppingListPage/DragContainer';
import { AppHelmet } from 'src/components/AppHelmet';
import { MenuDrawer, MenuItemType } from 'src/components/MenuDrawer';

const CardsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 24px;
`;

const ViewLinksContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

function ViewLinks() {
  return (
    <ViewLinksContainer>
      <Link href="/archive">Archived Lists</Link> &#65372; <Link href="/trash">Trashed Lists</Link>
    </ViewLinksContainer>
  );
}

export function ShoppingListsPage({ viewMode = '' }: { viewMode?: '' | 'archive' | 'trash' }) {
  const { navigate } = useNavigate();
  const {
    isLoading,
    orderedListStates,
    error,
    dragListState,
    setDragListId,
    setDragListToIndex,
    createShoppingList,
    updateShoppingList,
    deleteShoppingList,
    restoreShoppingList,
    updateShoppingListOrder,
  } = useShoppingLists();

  const containerRef = useRef<HTMLDivElement>(null);
  const listRefs = useRef(new Map<ShoppingListState, HTMLElement>());

  const updateListRef = (listState: ShoppingListState, el: HTMLElement | null) => {
    if (el) {
      listRefs.current.set(listState, el);
    } else {
      listRefs.current.delete(listState);
    }
  };

  const { handleDragStart, handleDragMove, handleDragEnd } = useListDragHandlers({
    listElements: listRefs.current,
    updateShoppingListOrder,
    orderedListStates,
    dragListState,
    setDragListId,
    setDragListToIndex,
  });

  const isListVisible = ({ list }: ShoppingListState) => {
    if (list.isArchived) {
      return viewMode === 'archive';
    }
    if (list.isInTrash) {
      return viewMode === 'trash';
    }
    return viewMode === '';
  };

  // TODO: indicate/prioritize pinned lists

  const openList = (listId: string) => {
    navigate(`/list/${listId}`);
  };

  const handleCreate = async (name: string) => {
    const newList = await createShoppingList(name);
    openList(newList.shoppingListId);
  };

  if (isLoading) {
    return <Spinner centered />;
  }

  let context;
  let showCreateList = false;
  switch (viewMode) {
    case 'archive':
      context = 'Archived Lists';
      break;
    case 'trash':
      context = 'Trashed Lists';
      break;
    default:
      context = 'Current Lists';
      showCreateList = true;
      break;
  }

  // TODO: consolidate menu item logic to central location
  const menuItems: MenuItemType[] = [
    {
      name: 'Lists',
      isActive: true,
      href: '/',
    },
    {
      name: 'Recipes',
      href: '/recipes',
    },
  ];

  return (
    <>
      <AppHelmet context={context} />
      <PageHeading backButton={viewMode !== ''}>
        <HeadingText>{context}</HeadingText>
      </PageHeading>
      {viewMode === '' && <MenuDrawer menuItems={menuItems} />}
      {showCreateList && (
        <Spacer $mt="24px" $flex>
          <CreateShoppingList onSubmit={handleCreate} />
        </Spacer>
      )}
      {error && (
        <Spacer $mt="24px" $flex>
          <ErrorText>{error}</ErrorText>
        </Spacer>
      )}
      <CursorContextProvider onChange={handleDragMove}>
        <CardsContainer ref={containerRef} onLostPointerCapture={handleDragEnd}>
          {orderedListStates.filter(isListVisible).map((state, index) => (
            <ShoppingListSummary
              key={state.list.shoppingListId}
              ref={(el) => updateListRef(state, el)}
              dragContainerRef={containerRef}
              shoppingList={state.list}
              isLoading={state.isLoading}
              isHidden={state === dragListState}
              onClick={() => openList(state.list.shoppingListId)}
              onDragStart={() => handleDragStart(state.list.shoppingListId, index)}
              onUpdate={updateShoppingList}
              onDelete={deleteShoppingList}
              onRestore={restoreShoppingList}
            />
          ))}
          {dragListState && containerRef.current && (
            <DragContainer
              parent={containerRef.current}
              onScroll={handleDragMove}
              hideShadow
              unlockX
            >
              <ShoppingListSummary
                dragContainerRef={containerRef}
                shoppingList={dragListState.list}
                isLoading={false}
                isHidden={false}
                onClick={() => undefined}
                onDragStart={() => undefined}
                onUpdate={() => undefined}
                onDelete={() => undefined}
                onRestore={() => undefined}
              />
            </DragContainer>
          )}
        </CardsContainer>
      </CursorContextProvider>
      {viewMode === '' && <ViewLinks />}
    </>
  );
}
