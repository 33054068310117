import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

type RouteHandler = (pathParams: PathParams) => JSX.Element;
export type Route = [string, RouteHandler];

export class PathParams {
  private params = new Map<string, string>();

  set(placeholder: string, value: string) {
    this.params.set(placeholder, value);
  }

  get(placeholder: string): string {
    return this.params.get(placeholder) || '';
  }
}

const StyledA = styled.a<{ $disabled: boolean }>`
  ${(p) => p.$disabled && 'color: gray;'}
  text-decoration: underline;
`;

const NavigateContext = createContext<{
  navigate: (path: string, replaceHistory?: boolean) => void;
  backOrDefault: (defaultPath: string) => void;
}>({ navigate: () => {}, backOrDefault: () => {} });

export function useNavigate() {
  return useContext(NavigateContext);
}

export function Link({
  href,
  disabled = false,
  replaceHistory = false,
  children,
  className,
}: {
  href: string;
  disabled?: boolean;
  replaceHistory?: boolean;
  children: React.ReactNode;
  className?: string;
}) {
  const { navigate } = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      return;
    }

    event.preventDefault();

    navigate(href, replaceHistory);
  };

  return (
    <StyledA
      className={className}
      href={disabled ? undefined : href}
      onClick={handleClick}
      $disabled={disabled}
    >
      {children}
    </StyledA>
  );
}

function matchRoute(routePath: string, pathParts: string[]): PathParams | undefined {
  if (routePath === '*') {
    return new PathParams();
  }

  const routeParts = routePath.split('/');
  if (pathParts.length != routeParts.length) {
    return undefined;
  }

  const pathParams = new PathParams();
  for (let i = 0; i < routeParts.length; i++) {
    const routePart = routeParts[i];
    const pathPart = pathParts[i];
    if (routePart.startsWith(':')) {
      pathParams.set(routePart.substring(1), pathPart);
    } else if (routePart !== pathPart) {
      return undefined;
    }
  }

  return pathParams;
}

export function Router({ routes }: { routes: Route[] }) {
  const [path, setPath] = useState(window.location.pathname);
  const [isUpdatePending, setIsUpdatePending] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      setPath(window.location.pathname);
    };

    addEventListener('popstate', handlePopState);

    return () => {
      removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleControllerChange = () => {
      setIsUpdatePending(true);
    };

    navigator.serviceWorker?.addEventListener('controllerchange', handleControllerChange);

    return () => {
      navigator.serviceWorker?.removeEventListener('controllerchange', handleControllerChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
  }, [path]);

  const navigate = useCallback(
    (newPath: string, replaceHistory = false) => {
      if (replaceHistory) {
        history.replaceState({}, '', newPath);
      } else {
        history.pushState({}, '', newPath);
      }

      if (isUpdatePending) {
        // Full reload to update app
        window.location.reload();
      } else {
        setPath(newPath);
      }
    },
    [isUpdatePending]
  );

  const backOrDefault = (defaultPath: string) => {
    // Presence of state implies that we were frontend-routed here and it is safe to go back
    if (window.history.state) {
      window.history.back();
    } else {
      navigate(defaultPath);
    }
  };

  const rendered = useMemo(() => {
    const pathParts = path.split('/');
    // Allow matching with an implicit trailing slash
    const pathPartsTrailingSlash = (path + '/').split('/');
    for (const [routePath, routeRenderer] of routes) {
      const pathParams =
        matchRoute(routePath, pathParts) ?? matchRoute(routePath, pathPartsTrailingSlash);
      if (pathParams) {
        return routeRenderer(pathParams);
      }
    }

    return <>404 - page not found</>;
  }, [routes, path]);

  return (
    <NavigateContext.Provider value={{ navigate, backOrDefault }}>
      {rendered}
    </NavigateContext.Provider>
  );
}
