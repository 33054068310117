import { ReactNode, useState } from 'react';
import { NakedButton } from './common';
import styled from 'styled-components';
import { Link } from './Router';

export interface MenuItemType {
  name: string;
  isActive?: boolean;
  href: string;
}

const StyledOpenButton = styled(NakedButton)`
  position: fixed;
  top: 10px;
  left: 10px;
`;

const StyledCloseButton = styled(NakedButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

// TODO: open/close animation
const StyledDrawer = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  z-index: 1500;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80vw;
  max-width: 500px;
  background: white;
  padding-top: 64px;

  ${(p) => !p.$isOpen && 'visibility: hidden;'}

  border-right: 1px solid black;
  box-shadow: 5px 0 5px #bbb;
`;

// TODO: colors
const StyledMenuDrawerLink = styled(Link)<{ $isActive: boolean }>`
  display: block;
  padding: 10px 20px;
  text-align: left;
  cursor: pointer;
  text-decoration: none;
  color: black;

  &:hover {
    ${(p) => !p.$isActive && 'background-color: #fafafa;'}
  }

  ${(p) => p.$isActive && 'background-color: #cfdfef;'}
`;

function DrawerCloseButton({ onClick }: { onClick: () => void }) {
  // TODO: icons
  return (
    <StyledCloseButton onClick={onClick}>
      <CloseIcon />
    </StyledCloseButton>
  );
}

function Drawer({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}) {
  return (
    <StyledDrawer $isOpen={isOpen}>
      {children}
      <DrawerCloseButton onClick={onClose} />
    </StyledDrawer>
  );
}

function CloseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="size-6"
      width={24}
      height={24}
    >
      <path
        fillRule="evenodd"
        d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function MenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="size-6"
      width={24}
      height={24}
    >
      <path
        fillRule="evenodd"
        d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

function DrawerOpenButton({ isOpen, onOpen }: { isOpen: boolean; onOpen: () => void }) {
  // TODO: icons
  if (isOpen) {
    return null;
  }

  return (
    <StyledOpenButton onClick={onOpen}>
      <MenuIcon />
    </StyledOpenButton>
  );
}

function MenuDrawerItem({ item }: { item: MenuItemType }) {
  return (
    <StyledMenuDrawerLink $isActive={!!item.isActive} href={item.href}>
      {item.name}
    </StyledMenuDrawerLink>
  );
}

export function MenuDrawer({ menuItems }: { menuItems: MenuItemType[] }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DrawerOpenButton isOpen={isOpen} onOpen={() => setIsOpen(true)} />
      <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {menuItems.map((item) => (
          <MenuDrawerItem key={item.name} item={item} />
        ))}
      </Drawer>
    </>
  );
}
